.summary-blocks__head {
	align-items: center;
	display: flex;
	flex: 0 1 100%;
	justify-content: space-between;
	margin-bottom: $grid-gutter-width-half;

	.btn {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

.summary-blocks__items {
	display: flex;
	flex-wrap: wrap;
	margin: -8px;

	@include media-breakpoint-up(xl) {
		margin: -$grid-gutter-width-half;
	}

	.box {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		flex: 1;
		margin: 8px;
		max-width: 510px;
		padding: 24px;

		@include media-breakpoint-up(xl) {
			margin: $grid-gutter-width-half;
			padding: $grid-gutter-width $grid-gutter-width-half $grid-gutter-width $grid-gutter-width;
		}

		.scroll-down {
			margin-top: auto;
		}

		h3 {
			margin: 0;
		}

		h5 {
			margin-bottom: $grid-gutter-width;
		}
	}

	.label {
		margin-bottom: $grid-gutter-width-half;
	}
}

.summary-blocks__items-title {
	border-bottom: 2px solid $gray-200;
	padding-bottom: 24px;
	margin-bottom: 16px;

	.icon {
		margin-right: $grid-gutter-width-half;
	}
}

.summary-blocks__bars {
	.progress {
		border-radius: 0;
		flex: 0 0 calc(100% - 156px);
		height: 40px;
		padding: 0 70px 0 0;
	}

	.progress-bar {
		color: $text-purple;
		font-size: 32px;
		font-weight: 700;
		text-align: right;
		text-indent: calc(100% + 16px);

		&.bg-info {
			background-color: $color-33;
		}

		&.bg-success {
			background-color: $color-53;
		}

		&.bg-danger {
			background-color: $color-64;
		}

		&.bg-light {
			background-color: $gray-300;
		}
	}
}

.summary-blocks__bar {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 12px;
}